.newsletter-block {
  .content__gdpr,
  .content__gdprLegal{
    font-size: 12px;
  }

  .newsletter-signup-text{
    text-transform: none;
    font-size: 14px;
  }
}