#mobileMenu {
  .select-language {
    display: flex;

    & > li {
      flex: 1 1 100%;

      & a {
        width: 100%;
        margin: 0;
        background-position: center center;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: -1px;
          background-color: white;
          width: 1px;
          z-index: 1;
        }
      }
    }
  }
}